<p-dialog
  header="JOGAR DE NOVO"
  [modal]="true"
  [(visible)]="showModalJogo"
  [draggable]="false"
  [resizable]="false"
  class="modal-jogo-style box-modal"
  [style]="{ width: '50rem' }"
>
  <div class="container">
    <div class="row">
      <div class="col-10 mx-auto">
        <div
          class="border mx-2 py-2 border-2 bes-border-color-tertiary-light rounded-4"
        >
          <p
            class="alegreyasans-black bes-fs-32 bes-color-clean text-center align-items-center"
          >
          {{ timeLeft }}
          </p>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-6 d-flex justify-content-end">
        <button
          class="btn btn-play rounded-4 d-flex align-items-center justify-content-center position-relative"
          (click)="sim()"
        >
          <span class="alegreyasans-black bes-color-clean bes-fs-20 me-1"
            >PLAY</span
          >
          <div
            class="position-relative d-flex align-items-center justify-content-center ps-1 pe-3"
          >
            <img
              src="./assets/project/Grupo 14.png"
              alt="Ícone"
              class="img-fluid"
            />
            <span
              class="position-absolute top-50 start-50 translate-middle alegreyasans-black bes-color-clean bes-fs-22 ps-4 ps-sm-5"
              >{{ dataMachine.product.priceCredit }}</span
            >
          </div>
        </button>
      </div>
      <div class="col-6">
        <button
          class="btn-jogo rounded-4 alegreyasans-black bes-color-clean bes-fs-20"
          (click)="nao()"
        >
          NÃO
        </button>
      </div>
    </div>

    <div class="row mt-4 mb-5">
      <div class="col-12 d-flex justify-content-center">
        <a
          class="bes-color-clean cursor-pointer"
          (click)="redirectComprarFichas()"
          >Comprar mais Plush Coins</a
        >
        <div class="ps-3">
          <img
            class="bes-h-25"
            src="./assets/project/PPlushcoin.png"
            alt="ticket"
          />
        </div>
      </div>
    </div>
  </div>
</p-dialog>
