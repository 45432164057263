import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AccessService } from '../../core/services/access.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { UsuarioController } from '../../core/controllers/usuarios.controller';
import { CoinService } from '../../core/services/coin.service';
import { ENUM_TIPO_USER } from '../../core/helpers/enum';

@Component({
  selector: 'toy-header',
  templateUrl: './header.component.html',
})
export class ToyHeaderComponent implements OnInit {
  constructor(
    private accessService: AccessService,
    private coinService: CoinService,
    private _router: Router,
    private usuarioController: UsuarioController
  ) {}

  saldos: any;
  cartQtd: any;

  @Input() ambienteDetails: any;
  @Input() isPainel: boolean = true;
  @Input() showBack: boolean = false;

  @ViewChild('menu') menu?: any;

  // isAuth = false
  isAuthenticated = false;

  items: MenuItem[] = [
    {
      label: 'Carrinho',
      command: () => this.navigateTo('carrinho'),
    },
    {
      label: 'Conta',
      command: () => this.navigateTo('perfil/conta'),
    },
    {
      label: 'Afiliados',
      command: () => this.navigateTo('perfil/afiliados'),
    },
    {
      label: 'Depósitos e Saques',
      command: () => this.navigateTo('perfil/deposito-saque'),
    },
    {
      label: 'Histórico de jogos',
      command: () => this.navigateTo('perfil/historico-jogos'),
    },
    {
      label: 'Meus Pedidos',
      command: () => this.navigateTo('perfil/meus-pedidos'),
    },
    {
      label: 'Sair',
      command: () => this.logout(),
    },
  ];

  ngOnInit(): void {
    const subRes = (isAuth: boolean) => {
      this.isAuthenticated = isAuth;
      this.getSaldosHeader();
    };

    this.accessService.authState$.subscribe(subRes);
    this.accessService.check().subscribe(subRes);
    this.coinService.ticketSubject$.subscribe(this.getSaldosHeader);

    if (this.accessService.access.typeUserId != ENUM_TIPO_USER.INFLUENCER) {
      this.items.splice(2, 1);
    }
  }

  getSaldosHeader = () => {
    this.getSaldo();
    this.getCart();
  };

  navigateTo(route: string) {
    this._router.navigate([route]);
  }

  clickMaquinas() {
    this._router.navigate(['maquina'])
  }

  logout() {
    this.accessService.sair();
    this._router.navigate(['/']);
  }

  getSaldo = () => {
    if (this.isAuthenticated) {
      this.usuarioController.getSaldo().subscribe({
        next: (k) => {
          this.saldos = k.data;
        },
      });
    }
  };

  getCart = () => {
    if (this.isAuthenticated) {
      this.usuarioController.getCart().subscribe({
        next: (k) => {
          this.cartQtd = k.data?.CartItem?.length || 0;
        },
      });
    }
  };

  clickGame = () => {
    this._router.navigate(['maquina']);
  };

  goToCart = () => {
    this._router.navigate(['carrinho']);
  };
  
  goToBuyCredits = () => {
    this._router.navigate(['perfil/deposito-saque']);
  };
}
