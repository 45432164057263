import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'toy-maquina-card',
  templateUrl: './maquina-card.component.html',
})
export class MaquinaCardComponent implements OnInit {
  constructor(private _router: Router) {}

  @Input() item: any;
  @Input() uso: boolean = false;

  ngOnInit(): void {}

  clickCard = () => {
    this._router.navigate([`play/${this.item.id}`]);
  };
}
