export const menu = [
  // {
    // code: 'p1',
    // description: 'Controle',
    // url: 'painel/controle',
    // type: 1,
    // children: [
      {
        code: 'f1.1',
        url: 'painel/controle/produtos',
        type: 2,
        description: 'Produtos',
      },
      {
        code: 'f1.2',
        url: 'painel/controle/maquinas',
        type: 2,
        description: 'Máquinas',
      },
      {
        code: 'f1.3',
        url: 'painel/controle/influencers',
        type: 2,
        description: 'Influencers',
      },
      {
        code: 'f1.4',
        url: 'painel/controle/pedidos',
        type: 2,
        description: 'Pedidos',
      },
      {
        code: 'f1.4',
        url: 'painel/controle/roleta',
        type: 2,
        description: 'Roleta da Sorte',
      },
      {
        code: 'f1.6',
        url: 'painel/controle/configuracoes',
        type: 2,
        description: 'Configurações',
      },
  //   ],
  // },
  // {
  //   code: 'p2',
  //   description: 'Influencers',
  //   url: 'painel/influencers',
  //   type: 1,
  //   children: [
  //     {
  //       code: 'f2.1',
  //       url: 'analitics',
  //       type: 2,
  //       description: 'Analítico',
  //     },
  //   ],
  // },
];
