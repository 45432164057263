<div class="container-fluid">
  <div
    class="row bes-h-80 border-bottom border-1 bes-border-color-gray-dark border-opacity-10"
  >
    <div class="d-none d-md-block col-4 col-lg-5">
      <img
        class="bes-w-200 ps-5 mt-2 cursor-pointer"
        src="./assets/project/Fortune Plush logo.png"
        alt=""
        (click)="clickGame()"
      />
    </div>
    @if(isAuthenticated){

    <!-- Header desk -->
    <div class="col-md-8 col-12 col-lg-7 d-none d-md-block">
      <div class="row ms-3">
        <div class="col-12">
          <div class="row justify-content-end">
            <div class="col-md-3 col-4 d-md-block">
              <div
                class="cursor-pointer row border rounded-3 bes-border-color-tertiary justify-content-end mt-4 me-3"
                (click)="goToCart()"
              >
                <div
                  class="col-6 p-0 d-flex justify-content-center align-items-center"
                >
                  <div class="p-0 bes-color-clean text-end">
                    {{ cartQtd || 0 }}
                  </div>
                </div>
                <div
                  class="col-3 p-md-0 d-flex justify-content-center align-items-center"
                >
                  <div class="p-1">
                    <img
                      class="bes-h-16"
                      src="./assets/project/FontAwsome (box-open).png"
                      alt="box-open"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4 col-md-3 d-none">
              <!-- SEM TICKET POR ENQUANTO -->
              <div
                class="row border rounded-3 bes-border-color-tertiary mt-4 me-3 d-flex justify-content-end justify-content-md-center flex-nowrap"
              >
                <div class="col-9">
                  <div class="p-1 bes-color-clean text-end">
                    {{ saldos?.totalTicketValue || 0 }}
                  </div>
                </div>
                <div
                  class="col-3 p-md-0 d-flex justify-content-center align-items-center"
                >
                  <div class="p-1">
                    <img
                      class="bes-h-16 bes-w-16"
                      src="./assets/project/FontAwsome (ticket-alt).png"
                      alt="box-open"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4 col-md-3">
              <div
                class="cursor-pointer row border rounded-3 bes-border-color-tertiary mt-4 me-3 d-flex justify-content-between flex-nowrap"
                (click)="goToBuyCredits()"
              >
                <div
                  class="col-3 p-md-0 d-flex justify-content-center align-items-center"
                >
                  <div>
                    <img
                      class="bes-h-25"
                      src="./assets/project/PPlushcoin.png"
                      alt="coins"
                    />
                  </div>
                </div>
                <div
                  class="col-6 p-0 w-auto d-flex justify-content-center align-items-center"
                >
                  <div class="p-0 bes-color-clean text-end text-md-center">
                    {{ saldos?.totalCreditValue || 0 }}
                  </div>
                </div>
                <div
                  class="col-3 p-md-0 d-flex justify-content-center align-items-center"
                >
                  <div class="p-1">
                    <img
                      class="bes-h-16"
                      src="./assets/project/Grupo 14.png"
                      alt="coins"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4 col-md-3">
              <div
                class="cursor-pointer row border rounded-3 bes-border-color-bonus mt-4 me-3 d-flex justify-content-between flex-nowrap"
                (click)="goToBuyCredits()"
              >
                <div
                  class="col-3 p-md-0 d-flex justify-content-center align-items-center"
                >
                  <div>
                    <img
                      class="bes-h-25"
                      src="./assets/project/PPlushcoin.png"
                      alt="coins"
                    />
                  </div>
                </div>
                <div
                  class="col-6 w-auto p-0 d-flex justify-content-center align-items-center"
                >
                  <div class="p-1 bes-color-bonus text-end text-md-center">
                    {{ saldos?.totalBonusCreditValue || 0 }}
                  </div>
                </div>
                <div
                  class="col-3 p-md-0 d-flex justify-content-center align-items-center"
                >
                  <div>
                    <p class="bes-fs-8 bes-color-bonus">BÔNUS</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3 d-none d-md-flex">
              <div class="row">
                <div class="col-12">
                  <div class="pe-3 mt-4 me-2" (click)="menu.toggle($event)">
                    <img
                      src="./assets/project/Grupo 137.png"
                      alt="Toggle Menu"
                      style="cursor: pointer"
                      class="bes-h-36"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p-tieredMenu
          #menu
          [model]="items"
          [popup]="true"
          class="custom-menu"
          styleClass="modal-header-perfil"
        ></p-tieredMenu>
      </div>
    </div>

    <!-- Header mobile -->
    <div class="col-12 d-block d-md-none">
      <div class="row">
        <div class="col-12">
          <div class="row mt-4">
            <div class="col">
              <div
                class="d-flex justify-content-center align-items-center h-full"
              >
              @if (showBack) {
                <i
                  class="cursor-pointer pi pi-angle-double-left me-2 bes-fs-20"
                  (click)="clickMaquinas()"
                ></i>
              }
                <div class="col" (click)="goToCart()">
                  <div
                    class="cursor-pointer p-1 border rounded-3 bes-border-color-tertiary d-flex justify-content-between align-items-center"
                  >
                    <div class="bes-color-clean text-end">
                      {{ cartQtd || 0 }}
                    </div>
                    <img
                      class="bes-h-16 ps-1"
                      src="./assets/project/FontAwsome (box-open).png"
                      alt="box-open"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col" (click)="goToBuyCredits()">
              <div
                class="cursor-pointer p-1 border rounded-3 bes-border-color-tertiary d-flex justify-content-between align-items-center"
              >
                <img
                  class="bes-h-22"
                  src="./assets/project/PPlushcoin.png"
                  alt="coins"
                />

                <div>
                  {{ saldos?.totalCreditValue || 0 }}
                </div>
                <img
                  class="bes-h-16"
                  src="./assets/project/Grupo 14.png"
                  alt="coins"
                />
              </div>
            </div>

            <div class="col" (click)="goToBuyCredits()">
              <div
                class="cursor-pointer p-1 border rounded-3 bes-border-color-bonus d-flex justify-content-between align-items-center"
              >
                <img
                  class="bes-h-25"
                  src="./assets/project/PPlushcoin.png"
                  alt="coins"
                />
                {{ saldos?.totalBonusCreditValue || 0 }}

                <p class="bes-fs-10 bes-color-bonus">BÔNUS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    }@else{
    <div class="col-md-7 col-12 pe-md-5">
      <div class="row">
        <div
          class="d-flex d-none d-md-flex col-md-5 d-flex justify-content-center justify-content-xxl-end pe-5 pe-xl-0"
        >
          <div class="d-flex align-items-center">
            <h5 class="alegreyasans-black bes-fs-20 mt-4 pulse">
              COMECE POR AQUI >>
            </h5>
          </div>
        </div>

        <div class="d-flex col-md-3 col-6 d-flex justify-content-end mt-3">
          <div class="p-1">
            <p-button
              styleClass="rounded-4 alegreyasans-black bes-fs-18 gradient-btn-tertiary border-0 bes-w-150"
              label="CADASTRE-SE"
              [rounded]="true"
              [raised]="true"
              severity="info"
              (onClick)="mc.exibirModalCadastro()"
            />
          </div>
        </div>
        <div class="d-flex col-md-4 col-6 mt-3 d-flex justify-content-start">
          <div class="p-1">
            <p-button
              styleClass="rounded-4 alegreyasans-black bes-fs-18 gradient-btn-primary border-0 bes-w-150"
              label="LOGIN"
              [rounded]="true"
              [raised]="true"
              severity="info"
              (onClick)="ml.exibirModalLogin()"
            />
          </div>
        </div>
      </div>
    </div>
    }
  </div>
</div>
<toy-modal-cadastro #mc></toy-modal-cadastro>
<toy-modal-login #ml></toy-modal-login>
