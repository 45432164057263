import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'toy-games-card',
  templateUrl: './games-card.component.html',
})
export class GamesCardComponent implements OnInit {
  constructor(private _router: Router) {}

  @Input() item: any;

  ngOnInit(): void {}

  clickCard = () => {
    this._router.navigate([this.item.redirect]);
  };
}
