import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class MachineController {
  constructor(private httpClient: HttpClient) {}
  private prefix = environment.machineApi + '/';

  cima = (machineId: number) => {
    return this.httpClient.post(
      this.prefix + 'setabaixo/' + machineId,
      {
        value: 30,
      },
      {
        headers: new HttpHeaders({
          'x-access-token': environment.token,
        }),
      }
    );
  };
  direita = (machineId: number) => {
    return this.httpClient.post(
      this.prefix + 'setaesquerda/' + machineId,
      {
        value: 30,
      },
      {
        headers: new HttpHeaders({
          'x-access-token': environment.token,
        }),
      }
    );
  };
  baixo = (machineId: number) => {
    return this.httpClient.post(
      this.prefix + 'setacima/' + machineId,
      {
        value: 40,
      },
      {
        headers: new HttpHeaders({
          'x-access-token': environment.token,
        }),
      }
    );
  };
  esquerda = (machineId: number) => {
    return this.httpClient.post(
      this.prefix + 'setadireita/' + machineId,
      {
        value: 20,
      },
      {
        headers: new HttpHeaders({
          'x-access-token': environment.token,
        }),
      }
    );
  };

  consultarSaida = (machineId: number) => {
    return this.httpClient.get(this.prefix + 'consultar-estoque/' + machineId, {
      headers: new HttpHeaders({
        'x-access-token': environment.token,
      }),
    });
  };

  credito = (machineId: number) => {
    return this.httpClient.post(
      this.prefix + 'credito/' + machineId,
      {
        credito: 1,
      },
      {
        headers: new HttpHeaders({
          'x-access-token': environment.token,
        }),
      }
    );
  };

  descer = (machineId: number) => {
    return this.httpClient.post(
      this.prefix + 'descerhugo/' + machineId,
      {},
      {
        headers: new HttpHeaders({
          'x-access-token': environment.token,
        }),
      }
    );
  };
}
