<div class="{{ isPainel ? 'container-fluid' : 'container' }} mt-5">
  <div class="row d-none d-md-flex">
    <div class="col-6">
      <div class="row">
        <div class="align-items-start">
          <img
            class="logotipo-footer"
            src="./assets/project/Fortune Plush logo.png"
            alt="Logotipo"
          />
        </div>
        <div class="col-8">
          <p class="text-start poppins-regular bes-fs-14 bes-color-gray">
            Fortune Plush reúne os melhores prêmios e os maiores entusiastas
            para uma diversão ininterrupta.
          </p>
        </div>
      </div>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <div class="row">
        <div class="col-12 col-md-12 justify-align-end">
          <p
            class="text-uppercase my-3 font-weight-bold text-start bes-fs-14 bes-color-gray"
          >
            Legal
          </p>
          <p
            class="my-3 text-start hoverA cursor-pointer bes-fs-13 bes-color-gray"
            (click)="redirecTermsService()"
          >
            Terms of Service
          </p>
          <p
            class="my-3 text-start hoverA cursor-pointer bes-fs-13 bes-color-gray"
            (click)="redirecPrivacyPolicy()"
          >
            Privacy Policy
          </p>
          <p
            class="my-3 text-start hoverA cursor-pointer bes-fs-13 bes-color-gray"
            (click)="redirecCookiesPolicy()"
          >
            Cookies Policy
          </p>
          <p
            class="my-3 text-start hoverA cursor-pointer bes-fs-13 bes-color-gray"
            (click)="redirecSobreNos()"
          >
            Sobre Nós
          </p>
        </div>
      </div>
    </div>
  </div>

  <hr class="d-none d-sm-flex" />
  <div class="row mt-3 mb-5 d-none d-md-flex">
    <div class="col-6 d-flex">
      <p class="poppins-regular bes-fs-14 bes-color-gray">
        © 2024 Fortune Plush. Todos os direitos reservados.
      </p>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <div class="row me-4">
        <div class="col-6">
          <i class="pi pi-instagram hoverA cursor-pointer"></i>
        </div>
        <div class="col-6">
          <i class="pi pi-facebook hoverA cursor-pointer"></i>
        </div>
      </div>
    </div>
  </div>

  @if (isFooterOpen) {
  <div class="row d-block d-md-none">
    <div
      class="col-12 bes-h-200 bes-bg-color-black-dark rounded-top-5 border-top border-2 bes-border-color-quarternary position-fixed bes-bottom-55 z-2"
    >
      <div class="row">
        <div
          class="col-12 bes-h-10 d-flex justify-content-center align-items-center mt-3"
        >
          <div
            class="border-bottom border-4 bes-border-color-tertiary-light bes-w-180"
          ></div>
        </div>
        <div class="col-12 bes-h-90 d-flex justify-content-center">
          <div class="row">
            <div class="col-12 bes-w-216 mt-4">
              <button
                class="btn btn-lg gradient-btn-primary rounded-4 alegreyasans-black bes-color-clean bes-fs-20 w-full"
                (click)="redirectCarrinho()"
              >
                CARRINHO
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 bes-h-90 d-flex justify-content-center">
          <div class="row">
            <div class="col-12 bes-w-216">
              <button
                class="btn btn-lg gradient-btn-primary rounded-4 alegreyasans-black bes-color-clean bes-fs-20 w-full"
                (click)="redirectDepositoSaque()"
              >
                DEPÓSITO E SAQUE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  } @if (isFooterAccountOpen) {
  <div class="row d-block d-md-none">
    <div
      class="col-12 bes-h-600 bes-bg-color-black-dark rounded-top-5 border-top border-2 bes-border-color-quarternary position-fixed bes-bottom-55 z-2"
    >
      <div class="row">
        <div class="col-12 bes-h-10 d-flex justify-content-center mt-3">
          <div
            class="border-bottom border-4 bes-border-color-tertiary-light bes-w-180"
          ></div>
        </div>

        <div class="col-12 bes-h-70 d-flex justify-content-center mt-4">
          <div class="row">
            <div class="col-12 bes-w-216">
              <button
                class="btn btn-lg gradient-btn-primary rounded-4 alegreyasans-black bes-color-clean bes-fs-20 w-full"
                (click)="redirectConta()"
              >
                CONTA
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 bes-h-70 d-flex justify-content-center">
          <div class="row">
            <div class="col-12 bes-w-216">
              <button
                class="btn btn-lg gradient-btn-primary rounded-4 alegreyasans-black bes-color-clean bes-fs-20 w-full"
                (click)="redirectCarrinho()"
              >
                CARRINHO
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 bes-h-70 d-flex justify-content-center">
          <div class="row">
            <div class="col-12 bes-w-216">
              <button
                class="btn btn-lg gradient-btn-primary rounded-4 alegreyasans-black bes-color-clean bes-fs-20 w-full"
                (click)="redirectHistoricoJogos()"
              >
                HISTÓRICO DE JOGOS
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 bes-h-70 d-flex justify-content-center">
          <div class="row">
            <div class="col-12 bes-w-216">
              <button
                class="btn btn-lg gradient-btn-primary rounded-4 alegreyasans-black bes-color-clean bes-fs-20 w-full"
                (click)="logout()"
              >
                SAIR
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  }

  <div class="row {{ hiddenFooter ? 'd-none' : 'd-block d-md-none' }} ">
    <div
      class="col-12 bes-h-60 bes-bg-color-quarternary rounded-top-4 position-fixed fixed-bottom z-2"
    >
      <div class="row">
        <div class="col-4 d-flex justify-content-center mt-4">
          <img
            class="hoverA cursor-pointer bes-w-70"
            src="./assets/project/Fortune Plush logo.png"
            alt=""
            (click)="clickMaquinas()"
          />
        </div>
        <div class="col-4 d-flex justify-content-center">
          <i
            class="pi pi-angle-double-up bes-h-25 bes-bg-color-quarternary-clean rounded-bottom-3 px-4 cursor-pointer pt-1"
            (click)="clickFooter()"
          ></i>
        </div>
        <div class="col-4 d-flex justify-content-center mt-4">
          <img
            class="hoverA cursor-pointer"
            src="./assets/project/Account.png"
            alt=""
            (click)="clickAccount()"
          />
        </div>
      </div>
    </div>
  </div>
</div>
